export interface ISoftware {
  ID: number;
  name: string;
  description: string;
}

export const softwareInitialState: ISoftware = {
  ID: 0,
  name: "",
  description: ""
};


export interface ISoftwareApplication {
  ID: number;
  softwareID: number;
  name: string;
  description: string;
}

export const softwareApplicationInitialState: ISoftwareApplication = {
  ID: 0,
  softwareID: 0,
  name: "",
  description: ""
};


export interface ISoftwareVersionResponse {
  data: ISoftwareVersion[];
  count: number;
  lastPage: number;
  currentPage: number;
}

export interface ISoftwareVersion {
  ID: number;
  name: string;
  version: string;
  releaseDate: string;
  isActive: boolean;
  isFullRelease: boolean;
  description: string;
  operationSystemName: string;
  changeLog: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  fileCrc32: number;
  downloadCount: number;
  createdAt: string;
}


export const softwareVersionInitialState: ISoftwareVersion = {
  ID: 0,
  name: "",
  version: "",
  releaseDate: "",
  isActive: true,
  isFullRelease: true,
  description: "",
  operationSystemName: "",
  changeLog: "",
  fileName: "",
  fileSize: 0,
  fileType: "",
  fileCrc32: 0,
  downloadCount: 0,
  createdAt: ""
};

export interface ISoftwareVersionUser {
  id: number;
  name: string;
  surname: string;
  email: string;
  downloadDate: string;
}

export interface ISoftwareVersionBySoftwareID {
  ID: number;
  name: string;
  version: string;
  releaseDate: string;
  isActive: boolean;
  isFullRelease: boolean;
  description: string;
  operationSystemID: number;
  changeLog: string;
  uploaderID: number;
  uploader: string;
  file: {
    name: string;
    oldName: string;
    size: number;
    type: string;
    crc32: number;
    downloadCount: number;
    users: ISoftwareVersionUser[];
  };
  updatedAt: string;
  createdAt: string;
}


export const softwareVersionBySoftwareIDInitialState: ISoftwareVersionBySoftwareID = {
  ID: 0,
  name: "",
  version: "",
  releaseDate: "",
  isActive: true,
  isFullRelease: true,
  description: "",
  operationSystemID: 0,
  changeLog: "",
  createdAt: "",
  uploaderID: 0,
  updatedAt: "",
  uploader: "",
  file: {
    name: "",
    oldName: "",
    size: 0,
    type: "",
    crc32: 0,
    downloadCount: 0,
    users: [{
      id: 0,
      name: "",
      surname: "",
      email: "",
      downloadDate: "",
    }]
  }
};
