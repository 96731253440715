import { FC, useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import CardHeader from "../../../components/Card/CardHeader";
import {
  getSoftwareInfoPageLinkByID,
  getUserInfoPageLinkByID,
  SOFTWARE_PAGE_LINK,
  STATUS_CREATED
} from "../../../core/constants";
import CardBody from "../../../components/Card/CardBody";
import Row from "../../../components/Wrapper/Row";
import Col from "../../../components/Wrapper/Col/Col";
import { formatDateTime } from "../../../utils/date";
import Card from "../../../components/Card/Card";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { adminService } from "../../../services/adminService";
import useToast from "../../../hooks/useToast";
import {
  ISoftwareVersionBySoftwareID,
  ISoftwareVersionUser,
  softwareVersionBySoftwareIDInitialState
} from "../../../types/ISoftware";
import { bytesToMB } from "../../../utils/utils";
import AdminAccessWrapper from "../../../permission/AccessControlWrapper";
import OperationSystemSelect from "../../../module/UploadPage/OpetationSystemSelect";
import useTheme from "../../../hooks/useThemes";

const SoftwareInfoPage: FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const { theme } = useTheme();

  const navigate = useNavigate();
  const location = useLocation();


  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [osID, setOsID] = useState<number>(1);
  const [description, setDescription] = useState<string>("");
  const [changeLog, setChangeLog] = useState<string>("");

  const [softwareInfo, setSoftwareInfo] =
    useState<ISoftwareVersionBySoftwareID>(softwareVersionBySoftwareIDInitialState);

  const [oldSoftwareInfo, setOldSoftwareInfo] =
    useState<ISoftwareVersionBySoftwareID>(softwareVersionBySoftwareIDInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getSoftwareVersionInfoBySoftwareID(Number(id));

        if (response) {
          setSoftwareInfo(response);
          setOldSoftwareInfo(response);
          setChangeLog(response.changeLog);
          setDescription(response.description);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, []);

  const handleSwitchPage = () => {
    if (location.pathname === getSoftwareInfoPageLinkByID(Number(id))) {
      navigate(SOFTWARE_PAGE_LINK);
    } else {
      navigate(getSoftwareInfoPageLinkByID(Number(id)));
    }
  };

  const handleEditButtonChange = () => {
    setIsEditMode(true);
  };

  const handleCloseEditButtonChange = () => {
    setIsEditMode(false);
    setSoftwareInfo(oldSoftwareInfo);
  };

  const compareObject = (originalObj: ISoftwareVersionBySoftwareID, updatedObj: ISoftwareVersionBySoftwareID) => {
    interface IChanges {
      version?: string;
      description?: string;
      changeLog?: string;
      isFullRelease?: string;
      isActive?: string;
      fileName?: string;
      releaseDate?: string;
    }

    const changes: IChanges = {};

    for (const key in originalObj) {
      if (originalObj[key] !== updatedObj[key]) {
        changes[key] = updatedObj[key];
      }
    }

    return changes;
  };


  const handleSaveRowChanges = async () => {
    setIsEditMode(false);

    const changedFields = compareObject(oldSoftwareInfo, softwareInfo);
    const formData = {};

    if (changedFields.version) {
      Object.assign(formData, { version: changedFields.version });
    }

    if (changedFields.description) {
      Object.assign(formData, { description: changedFields.description });
    }

    if (changedFields.changeLog) {
      Object.assign(formData, { changeLog: changedFields.changeLog });
    }

    if (changedFields.fileName) {
      Object.assign(formData, { fileName: changedFields.fileName });
    }

    if (changedFields.releaseDate) {
      Object.assign(formData, { releaseDate: changedFields.releaseDate });
    }


    if (!(Object.entries(formData).length === 0)) {
      try {
        const response = await adminService.updateSoftwareVersion(softwareInfo.ID, formData);
        if (response.status == STATUS_CREATED) {
          setOldSoftwareInfo(softwareInfo);
          addToast(response.data.message, "success");
        }
      } catch (e) {
        setSoftwareInfo(oldSoftwareInfo);
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    }
  };


  useEffect(() => {
    setSoftwareInfo(prevState => ({
      ...prevState,
      operationSystemID: osID
    }));
  }, [osID]);

  useEffect(() => {
    setSoftwareInfo(prevData => ({
      ...prevData,
      description: description
    }));
  }, [description]);

  useEffect(() => {
    setSoftwareInfo(prevData => ({
      ...prevData,
      changeLog: changeLog
    }));
  }, [changeLog]);


  const handleOnChangeField = (e: any) => {
    let value: string;

    const { name: fieldName } = e.target;

    value = e.target.value;


    setSoftwareInfo(prevData => ({
      ...prevData,
      [fieldName]: value
    }));

  };

  const UserTableHead: FC = () => {
    return (
      <tr>
        <th className="text-center">№</th>
        <th className="text-center">Имя</th>
        <th className="text-center">Email</th>
        <th className="text-center">Дата</th>
      </tr>
    );
  };


  return (
    <Card>
      <CardHeader>
        <h6 className="mt-2">Загруженное ПО версия: {softwareInfo.version}</h6>
      </CardHeader>
      <AdminAccessWrapper>
        <Row>
          <div className="col-12">
            <button
              type="submit"
              onClick={handleSwitchPage}
              className="btn btn-primary btn-sm px-4 mt-4 ms-4"
              style={{ marginLeft: "auto" }}
            >
              Вернуться
            </button>
            {isEditMode && isEditMode ? (
              <>
                <button
                  type="submit"
                  onClick={handleSaveRowChanges}
                  className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                  style={{ marginLeft: "auto" }}
                >
                  Сохранить изменения
                </button>
                <button
                  type="submit"
                  onClick={handleCloseEditButtonChange}
                  className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                  style={{ marginLeft: "auto" }}
                >
                  Отменить редактирование
                </button>
              </>

            ) : (
              <>
                <button
                  type="submit"
                  onClick={handleEditButtonChange}
                  className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                  style={{ marginLeft: "auto" }}
                >
                  Редактировать
                </button>
              </>
            )}
            <hr />
          </div>
        </Row>
      </AdminAccessWrapper>
      <CardBody>

        <Card>
          <CardHeader>Сведения о ПО</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="software_name">
                    <strong>Название</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="software_name"
                    placeholder="Название ПО"
                    name="software_name"
                    value={softwareInfo.name}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="version">
                    <strong>Версия</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="version"
                    placeholder="Версия"
                    name="version"
                    value={softwareInfo.version}
                    disabled={!isEditMode}
                    onChange={handleOnChangeField}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <OperationSystemSelect
                    setOsID={setOsID}
                    disabled
                  />
                </div>
              </Col>
              <Col>

                <div className="mb-3">
                  <Link to={getUserInfoPageLinkByID(softwareInfo.uploaderID)}>

                    <label className="form-label" htmlFor="uploader">
                      <strong>Загрузил</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="uploader"
                      placeholder="Загрузил"
                      name="uploader"
                      value={softwareInfo.uploader}
                      disabled
                    />
                  </Link>
                </div>
              </Col>
            </Row>


            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="is_active">
                    <strong>Активная версия</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="is_active"
                    placeholder="Активная версия"
                    name="is_active"
                    value={softwareInfo.isActive ? "Да" : "Нет"}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="is_full_release">
                    <strong>Полный релиз</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="is_full_release"
                    placeholder="Полный релиз"
                    name="is_full_release"
                    value={softwareInfo.isFullRelease ? "Да" : "Нет"}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="description">
                    <strong>Описание</strong>
                  </label>
                  {!isEditMode ? (
                    <Card>
                      <CardBody>
                        <MDEditor.Markdown
                          source={softwareInfo.description}
                          style={{
                            backgroundColor: theme === "light" ? "white" : "#212529",
                            color: theme === "light" ? "black" : "white" // Черный текст
                          }}
                        />
                      </CardBody>
                    </Card>
                  ) : (
                    <MDEditor
                      value={description}
                      onChange={setDescription}
                      data-color-mode={theme === "light" ? "light" : "dark"}
                      className="mt-1"
                      height={"auto"}
                    />
                  )}

                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="changeLog">
                    <strong>Список изменений</strong>
                  </label>
                  {!isEditMode ? (
                    <Card>
                      <CardBody>
                        <MDEditor.Markdown
                          source={softwareInfo.changeLog}
                          style={{
                            backgroundColor: theme === "light" ? "white" : "#212529",
                            color: theme === "light" ? "black" : "white" // Черный текст
                          }}
                        />
                      </CardBody>
                    </Card>
                  ) : (
                    <MDEditor
                      value={changeLog}
                      onChange={setChangeLog}
                      data-color-mode={theme === "light" ? "light" : "dark"}
                      className="mt-1"
                      height={"auto"}
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="releaseDate">
                    <strong>Дата релиза</strong>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    id="releaseDate"
                    placeholder="Дата релиза"
                    name="releaseDate"
                    onChange={handleOnChangeField}
                    value={softwareInfo.releaseDate}
                    // value={selDate}
                    disabled={!isEditMode}
                  />
                </div>
              </Col>

              <Col>
                <></>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="updatedAt">
                    <strong>Дата последнего обновления записи</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="updatedAt"
                    placeholder="Дата последнего обновления записи"
                    name="updatedAt"
                    value={softwareInfo.updatedAt ? formatDateTime(softwareInfo.updatedAt) : ""}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="created_at">
                    <strong>Дата создания записи</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="created_at"
                    placeholder="Дата создания записи"
                    name="created_at"
                    value={softwareInfo.createdAt ? formatDateTime(softwareInfo.createdAt) : ""}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>


        <Card className="mt-3">
          <CardHeader>Сведения о файле</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="fileName">
                    <strong>Имя файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="fileName"
                    placeholder="Имя файла"
                    name="fileName"
                    value={softwareInfo.file.name}
                    onChange={handleOnChangeField}
                    disabled={!isEditMode}
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_old_name">
                    <strong>Начальное имя файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_old_name"
                    placeholder="Начальное имя файла"
                    name="file_old_name"
                    value={softwareInfo.file.oldName ? softwareInfo.file.oldName : "Отсутствует"}
                    disabled
                  />
                </div>
              </Col>
            </Row>


            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_type">
                    <strong>Тип файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_type"
                    placeholder="Тип файла"
                    name="file_type"
                    value={softwareInfo.file.type}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_size">
                    <strong>Размер файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_size"
                    placeholder="Размер файла"
                    name="file_size"
                    value={bytesToMB(softwareInfo.file.size) + " МБ"}
                    disabled
                  />
                </div>
              </Col>
            </Row>


            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_crc_32">
                    <strong>CRC32 Файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_crc_32"
                    placeholder="CRC32 Файла"
                    name="file_crc_32"
                    value={"0x" + softwareInfo.file.crc32.toString(16)}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_download_count">
                    <strong>Количество скачиваний</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_download_count"
                    placeholder="Количество скачиваний"
                    name="file_download_count"
                    value={softwareInfo.file.downloadCount}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            {softwareInfo.file.users.length > 0 && (
              <Row>
                <Col>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="created_at">
                      <strong>Пользователи, которые скачали файл</strong>
                    </label>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered my-0">
                        <thead>
                        <UserTableHead />
                        </thead>
                        <tbody>
                        {softwareInfo.file.users.map((row: ISoftwareVersionUser, key: number) => (
                          <tr key={row.id}>
                            <td className={"align-middle text-center"}>{key}</td>
                            <td className={"align-middle text-center"}>
                              <Link to={getUserInfoPageLinkByID(row.id)}>{row.name + " " + row.surname}</Link>
                            </td>
                            <td className={"align-middle text-center"}>{row.email}</td>
                            <td
                              className={"align-middle text-center"}>{row.downloadDate && formatDateTime(row.downloadDate)}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

          </CardBody>
        </Card>


      </CardBody>
    </Card>
  )
    ;
};

export default SoftwareInfoPage;